<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <el-row :gutter="0">
      <el-col :span="20" :offset="2">
        <header class="page-header">
          <el-row :gutter="20" align="middle">
            <el-col :span="12">
              <h2>Агуулахын жагсаалт</h2>
            </el-col>
            <el-col :span="12">
              <div class="actions text-right">
                <router-link to="/add-warehouse">
                  <el-button type="success" class="item">Нэмэх</el-button>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </header>
        <!-- Tabled content -->
        <div class="panel tabled">
          <el-tabs v-model="activeTabStatus">
            <el-tab-pane label="All" name="all">
              <div class="panel-content">
                  <div class="pl20 pr20 table-filter mb10">
                      <el-row :gutter="10" align="middle" type="flex">
                          <el-col :span="20">
                              <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                                  <el-button icon="el-icon-search" slot="prepend"></el-button>
                              </el-input>
                          </el-col>
                          <el-col :span="4" :offset="4" class="text-right">
                            <el-dropdown @command="handleSort">
                                <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in filterData" :key="indexFilter">
                                        {{filter.label}}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                          </el-col>
                      </el-row>
                  </div>
                </div>
              <div class="panel-content">
                <el-table
                  v-loading="loading"
                  :data="warehouses"
                  style="width: 100%"
                  @cell-click="clickCell"
                >
                  <el-table-column label="Монгол нэр"
                    prop="name_mon"
                  ></el-table-column>
                  <el-table-column label="Англи нэр"
                    prop="name_eng"
                  ></el-table-column>
                  <el-table-column label="Хаяг"
                    prop="address"
                  ></el-table-column>
                </el-table>
                <el-pagination
                  @current-change = "curentPageHandler"
                  @size-change = "sizeChangeHandler"
                  class="text-right mt10"
                  background
                  :current-page.sync="currentPage"
                  :page-size="pageSize"
                  :page-sizes="[5, 20, 50, 100]"
                  layout="total, sizes, prev, pager, next"
                  :total="totalCount">
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// // @ is an alias to /src
import services from '../../../helpers/services'
export default {
  name: 'transferList',
  data () {
    return {
      search: '',
      searchText: '',
      status: 'all',
      tableData: null,
      warehouses: [],
      loading: false,
      activeTabStatus: 'all',
      pageSize: 20,
      currentPage: 1,
      totalCount: 0,
      filterData: [
        {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size && this.$route.query.search) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getWarehouses(from, size, this.search)
  },
  methods: {
    onSearch () {
      this.$router.push({ name: 'warehouseList', query: { page: this.currentPage, size: this.pageSize, search: this.search } })
      this.getWarehouses(this.pageSize * (this.currentPage - 1), this.pageSize, this.search)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'warehouseList', query: { page: this.currentPage, size: this.pageSize, search: this.search } })
      this.getWarehouses((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'warehouseList', query: { page: item, size: this.pageSize, search: this.search } })
      this.getWarehouses((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.name_mon.localeCompare(b.name_mon))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.name_mon.localeCompare(a.name_mon))
      } else if (method === 'newest') {
        data.sort((a, b) => b.created_at.localeCompare(a.created_at))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.created_at.localeCompare(b.created_at))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.warehouses = this.sortMethods(data, this.warehouses)
    },
    getWarehouses (from, size, search) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size
      this.loading = true
      services.getWarehouses(query).then(response => {
        this.warehouses = response.datas
        this.totalCount = response.total
        this.loading = false
      })
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'editWarehouse',
          params: {
            warehouse_id: row.warehouse_id
          }
        }
      )
    }
  }
}
</script>
<style>
.el-table .cell {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
